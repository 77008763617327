import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import Layout from '../../components/v2/Layout';
import TowerDefenseLayout from '../../components/v2/tower-defense/TowerDefenseLayout';

const TowerDefense = () => {
  const { t } = useTranslation();

  return (
    <Layout title={t('Tower Defense')}>
      <TowerDefenseLayout />
    </Layout>
  );
};

export default TowerDefense;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
