import { DuplicateIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import React, { useState } from 'react';

import { truncateAddress } from '../../services/utils';

interface CopyableAddressProps {
  address: string;
  textClasses?: string;
  successMessageClasses?: string;
}

const CopyableAddress = ({ address, textClasses, successMessageClasses }: CopyableAddressProps) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(address);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div className="flex">
      <button
        className="cursor-pointer flex items-center"
        onClick={copyToClipboard}
        aria-label="Copy address"
        title="Copy address"
      >
        <span className={classNames('text-black', 'font-base', 'text-16', textClasses)}>
          {truncateAddress(address)}
        </span>
        <DuplicateIcon className={classNames('h-18', 'w-18', 'text-inherit', 'ml-4')} aria-hidden="true" />
      </button>
      {copied && (
        <span
          className={classNames(
            'text-volcaneers-primary-default',
            'font-base',
            'font-medium',
            'ml-4',
            successMessageClasses
          )}
        >
          Copied!
        </span>
      )}
    </div>
  );
};

export default CopyableAddress;
