import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

import TowerDefenseButton from '../TowerDefenseButton';

const FactionContentPanel = ({
  children,
  subtitle,
  title,
  description
}: {
  children?: React.ReactElement | React.ReactElement[] | React.ReactNode | React.ReactNode[];
  subtitle?: string;
  title?: string;
  description?: string | string[];
}) => {
  return (
    <div className="td-glass-bg py-24 px-16 md:p-32 text-white rounded-[24px]">
      <div className="space-y-16">
        {children ?? (
          <div className="space-y-16">
            <div className="space-y-8">
              {subtitle && <h4 className="text-20">{subtitle}</h4>}
              {title && <h3 className="font-bold text-32">{title}</h3>}
            </div>
            {description && (
              <>
                {Array.isArray(description) ? (
                  Array.from(description).map((desc, index) => (
                    <p className="text-white" key={index}>
                      {desc}
                    </p>
                  ))
                ) : (
                  <p className="text-white">{description}</p>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="mt-24">
        <TowerDefenseButton
          outlineVariant
          smallVariant
          renderAsLink
          to="https://tower-defense.paimastudios.com"
          target="_blank"
        >
          <Trans>Play now</Trans>
        </TowerDefenseButton>
      </div>
    </div>
  );
};

export default FactionContentPanel;
