import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import YoutubeIcon from '../../../../static/images/icon-youtube-full.inline.svg';
import SymbolsLarge from '../../../../static/images/td-symbols-medium.inline.svg';
import SymbolsSmall from '../../../../static/images/td-symbols-small.inline.svg';
import { socialsConfig } from '../../../config';
import { getLocalizedLink } from '../../../utils/locale';
import TowerDefenseButton from './TowerDefenseButton';

const ContactUs = () => {
  const { i18n } = useTranslation();

  return (
    <div className="bg-tower-defense-gray-default pt-72 pb-72 md:pt-96 overflow-x-clip">
      <div className="container-slim relative">
        <div className="absolute -left-[140px] top-[56px] hidden md:block">
          <SymbolsLarge />
        </div>
        <div className="absolute -right-[140px] -bottom-[40px] hidden md:block z-20">
          <SymbolsSmall />
        </div>
        <div className="relative z-30">
          <h2 className="text-32 xs:text-48 text-white font-bold">
            <Trans>Contact us</Trans>
          </h2>
          <p className="text-white mt-16">
            <Trans>
              Feel free to reach out to our team for inquiries, feedback, assistance, or issue reporting. You can
              contact us via email, social media, or our dedicated contact form.
            </Trans>
          </p>
          <div className="mt-40 md:mt-64 space-y-24">
            <div className="bg-tower-defense-gray-light text-white rounded-[32px] md:flex md:items-center">
              <div className="flex-1 md:basis-[50%]">
                <img src="/images/paima-engine-contact-us.webp" alt="" className="rounded-[32px]" />
              </div>
              <div className="flex-1 md:basis-[50%] p-24 lg:p-40">
                <h4 className="text-14 uppercase mb-8">
                  <Trans>Tutorials</Trans>
                </h4>
                <h3 className="font-bold text-24 lg:text-32 lg:max-w-[400px]">
                  <Trans>Watch Our YouTube Videos To Find Out More</Trans>
                </h3>
                <div className="mt-24 flex">
                  <TowerDefenseButton
                    iconComponent={<YoutubeIcon />}
                    to={socialsConfig.youtubeUrl}
                    target="_blank"
                    rel="noreferrer"
                    renderAsLink
                  >
                    <Trans>Watch on YouTube</Trans>
                  </TowerDefenseButton>
                </div>
              </div>
            </div>
            <div className="rounded-[32px] flex flex-col md:flex-row items-center md:items-stretch gap-24">
              <div className="flex-1 md:basis-[50%]">
                <div className="bg-tower-defense-gray-light text-white rounded-[32px] p-24 lg:p-40 md:min-h-full">
                  <h4 className="text-24 font-bold">
                    <Trans>Join the Community</Trans>
                  </h4>
                  <p className="mt-8">
                    <Trans>
                      Join the Paima Ecosystem and take part in building the future of Web3 gaming with us! Our discord
                      is the best place to ask questions about Paima Engine and to take part in the community.
                    </Trans>
                  </p>
                  <div className="mt-24 flex">
                    <TowerDefenseButton
                      outlineVariant
                      to={socialsConfig.discordUrl}
                      renderAsLink
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Trans>Join our Discord</Trans>
                    </TowerDefenseButton>
                  </div>
                </div>
              </div>
              <div className="flex-1 md:basis-[50%] md:min-h-full">
                <div className="bg-tower-defense-gray-light text-white rounded-[32px] p-24 lg:p-40">
                  <h4 className="text-24 font-bold">
                    <Trans>Build Games With Paima Engine</Trans>
                  </h4>
                  <p className="mt-8">
                    <Trans>
                      Interested in becoming one of the first companies to build Web3 games using Paima Engine? We're
                      always open to providing early access to teams who are extremely dedicated, have a solid track
                      record, and are ready to build the future of Web3.
                    </Trans>
                  </p>
                  <div className="mt-24 flex">
                    <TowerDefenseButton
                      outlineVariant
                      renderAsLink
                      to={getLocalizedLink('/contact', i18n.language)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Trans>Contact Us</Trans>
                    </TowerDefenseButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
