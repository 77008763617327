import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { useWeb3Context } from '../../../hooks/useWeb3Context';
import CookieConsentBanner from '../../CookieConsentBanner';
import Footer from '../Footer';
import Header from '../header/Header';
import ContactUs from './ContactUs';
import ChooseFaction from './faction/ChooseFaction';
import FAQ from './FAQ';
import Features from './Features';
import Hero from './Hero';
import LeaderboardSection from './LeaderboardSection';
import PoweredBy from './PoweredBy';

const TowerDefenseLayout = () => {
  const { connected } = useWeb3Context();
  const { t } = useTranslation();

  return (
    <div className="relative font-base bg-tower-defense-gray-default">
      <Header showSubHeader="tower-defense" connected={connected} />
      <CookieConsentBanner />
      <Hero />
      <div id="features" className="scroll-mt-[50px]">
        <Features />
      </div>
      <div id="about" className="scroll-mt-[70px]">
        <PoweredBy />
      </div>
      <div id="mechanics" className="scroll-mt-[50px]">
        <ChooseFaction />
      </div>
      <div id="leaderboard" className="scroll-mt-[50px]">
        <LeaderboardSection />
      </div>
      <div id="faq" className="scroll-mt-[50px]">
        <FAQ
          includeCharacters
          questions={[
            {
              question: t('What is Wrath Of The Jungle: Tower Defense?'),
              answer: t(
                "Wrath of the Jungle: Tower Defense is a fast-paced game where players choose to play as either attackers or defenders. As an attacker, players lead hordes of enemy creatures through the jungle to destroy the defender's base, while defenders use a variety of towers to fend off attackers."
              )
            },
            {
              question: t('Do I need to have an NFT to play?'),
              answer: t('No, you do not need an NFT to play, and there is no upfront cost to get started.')
            },
            {
              question: t('What are NFTs like Volcaneers for in the game?'),
              answer: t(
                'Using a Stateful NFT such as Volcs can enhance your experience by allowing you to track and showcase your game record on the blockchain. This record, which includes your wins, losses, and draws, can be accessed by anyone on the blockchain and can evolve and gain value as you continue to play the game. Additionally, having a well-established game record on the blockchain may get you the opportunity to take part in up and coming tournaments!'
              )
            },
            {
              question: t('Will I be able to play from Cardano, Astar, Ethereum, or Algorand mainnet?'),
              answer: (
                <Trans
                  i18nKey="In Q1 2023 we released a new piece of technology called Paima Whirlpool which allows users on Cardano Mainnet, Astar network, and Ethereum to play Paima Studio games using their native wallets! In the future Paima Whirlpool will also continue to be upgraded to allow for further multi-chain integrations including the likes of Algorand, and more!"
                  components={[<strong />]}
                />
              )
            },
            {
              question: t('Why is Metamask asking me to pay 3+ mADA for gas?!'),
              answer: t(
                'This happens because you have no mADA in your wallet and metamask gas estimation runs into trouble. Simply add mADA to your wallet and the fees will be properly calculated going forward.'
              )
            },
            {
              question: t('Why do I need to sign each transaction while playing?'),
              answer: (
                <Trans
                  i18nKey="Wrath of the Jungle: Tower Defense is on the forefront of pioneering trustless Web3 gaming, meaning all actions are stored on-chain. As such, players must submit their moves to the blockchain via signing their game inputs and submitting it to a batcher, or sending transactions themselves. This ensures that all game actions are trustless and verifiable, offering a level of decentralization not possible with traditional, off-chain or Web2.5 games. In the near future Paima games will support a new standard called 'Gaming Accounts' in wallets like Flint Wallet, which will auto-sign transactions and make the gameplay experience even more seamless."
                  components={[<strong />]}
                />
              )
            },
            {
              question: t('How do the lobbies work?'),
              answer: t(
                'Wrath of the Jungle: Tower Defense lets players create customized lobbies for matchmaking. Lobby creators can choose game parameters such as round time limit, map, lobby visibility, their faction, and more! There is no limit to the number of lobbies a player can create or join, meaning you can feel just like a chess grandmaster by playing 50 games at the same time!'
              )
            },
            {
              question: t('How can I rewatch or replay a match?'),
              answer: t(
                'In the main menu, click on the Play icon to the top right corner of the screen. This will open a new modal where you can paste in the Lobby ID and click Rewatch to see the action for yourself!'
              )
            },
            {
              question: t('What wallets can I use to play?'),
              answer: (
                <Trans
                  i18nKey="Currently, Paima Studio games can be played through Cardano wallets, EVM wallets, and Astar/Polkadot wallets. In the near future we will also be adding support for Algorand wallets, and potentially other wallets/networks as well!"
                  components={[
                    <a
                      className="underline cursor-pointer"
                      href="https://dcspark.github.io/milkomeda-documentation/cardano/for-end-users/configuring-metamask"
                      target="_blank"
                    />
                  ]}
                />
              )
            }
          ]}
        />
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default TowerDefenseLayout;
