import classNames from 'classnames';
import { GatsbyLinkProps, Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement } from 'react';

import { isLinkExternal } from '../../../services/utils';
import { getLocalizedLink } from '../../../utils/locale';

interface CommonProps {
  outlineVariant?: boolean;
  smallVariant?: boolean;
  customClassName?: string;
  iconComponent?: ReactElement;
  iconComponentPlacement?: 'first' | 'last';
  iconSpacingClassname?: string;
  iconComponentClassname?: string;
}

interface CleanGatsbyLinkProps extends Omit<GatsbyLinkProps<{}>, 'ref' | 'className'> {}

type ButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className'> &
  CommonProps & {
    renderAsLink?: false;
  };

type LinkProps = CleanGatsbyLinkProps &
  CommonProps & {
    renderAsLink: true;
  };

export type TowerDefenseButtonProps = ButtonProps | LinkProps;

const TowerDefenseButton = (props: TowerDefenseButtonProps) => {
  const { i18n } = useTranslation();

  const getButtonOutlineVariantClassNames = () => {
    if (!!props.outlineVariant) {
      return 'border border-white hover:border-tower-defense-yellow hover:bg-tower-defense-yellow';
    }

    return 'td-button-gradient';
  };

  const getButtonSizeVariantClassNames = () => {
    if (!!props.smallVariant) {
      return 'py-10 px-20';
    }

    return 'py-20 px-40';
  };

  const getCommonButtonStyles = () => {
    return [
      'font-bold text-16 sm:text-18 text-white',
      'rounded-[160px]',
      'block',
      'cursor-pointer',
      'disabled:cursor-not-allowed',
      'text-center',
      'block md:inline-block'
    ];
  };

  const getInnerButtonLayout = () => {
    return !!props.iconComponent ? (
      <div
        className={classNames(
          props.iconComponentPlacement === 'last' ? 'flex flex-row-reverse' : 'flex',
          'items-center justify-center',
          props.iconSpacingClassname ?? 'gap-8'
        )}
      >
        <div
          className={classNames(
            'fill-white w-[24px] h-[24px] flex items-center justify-center',
            props.iconComponentClassname
          )}
        >
          {props.iconComponent}
        </div>
        {props.children}
      </div>
    ) : (
      <div className="flex items-center justify-center">{props.children}</div>
    );
  };

  if (!!props.renderAsLink) {
    const {
      iconComponent,
      iconComponentPlacement,
      iconSpacingClassname,
      iconComponentClassname,
      customClassName,
      outlineVariant,
      smallVariant,
      renderAsLink,
      to: propTo,
      ...restProps
    } = props;

    const isExternalLink = isLinkExternal(propTo);

    if (isExternalLink) {
      return (
        <a
          href={propTo}
          className={classNames(
            getCommonButtonStyles(),
            getButtonOutlineVariantClassNames(),
            getButtonSizeVariantClassNames(),
            props.customClassName
          )}
          {...restProps}
        >
          {getInnerButtonLayout()}
        </a>
      );
    }

    return (
      <Link
        to={getLocalizedLink(propTo, i18n.language)}
        className={classNames(
          getCommonButtonStyles(),
          getButtonOutlineVariantClassNames(),
          getButtonSizeVariantClassNames(),
          props.customClassName
        )}
        activeClassName="underline underline-offset-8"
        {...restProps}
      >
        {getInnerButtonLayout()}
      </Link>
    );
  }

  const {
    iconComponent,
    iconComponentPlacement,
    iconSpacingClassname,
    iconComponentClassname,
    customClassName,
    outlineVariant,
    smallVariant,
    renderAsLink,
    ...restProps
  } = props;

  return (
    <button
      {...restProps}
      className={classNames(
        getCommonButtonStyles(),
        getButtonOutlineVariantClassNames(),
        getButtonSizeVariantClassNames(),
        props.customClassName
      )}
    >
      {getInnerButtonLayout()}
    </button>
  );
};

export default TowerDefenseButton;
