import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import classNames from 'classnames';
import FactionContentInner from './FactionContentInner';
import FactionContentPanel from './FactionContentPanel';
import FactionContentWrapper from './FactionContentWrapper';

const FightForGloryAttacker = () => {
  const { t } = useTranslation();

  return (
    <FactionContentWrapper>
      <FactionContentInner>
        <div>
          <video
            className={classNames(
              'w-full',
              'h-full',
              'object-cover',
              'aspect-square',
              'rounded-[24px]',
              'max-w-[450px] max-h-[450px]'
            )}
            playsInline
            autoPlay
            muted
            loop
          >
            <source className="object-cover" src="/video/td-attack.mp4" type="video/mp4" />
          </video>
        </div>
        <FactionContentPanel
          title={t('Destroy the base and Fight for Jungle Glory')}
          description={[
            t(
              "In Fight for Jungle Glory, attackers compete against other players to see who can achieve the highest score in the game. Attackers must strategically set up their crypts and deploy their animals to overcome the defender's towers and protect their own base. The ultimate goal is to climb the leaderboard and earn the title of Jungle Champion, proving themselves to be the strongest and most skilled player in the game. With each successful attack, attackers earn points and resources that they can use to upgrade their animals and improve their chances of success in future battles."
            )
          ]}
        />
      </FactionContentInner>
    </FactionContentWrapper>
  );
};

export default FightForGloryAttacker;
