import { Link } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React from 'react';

import TowerDefenseButton from './TowerDefenseButton';

import ArrowIcon from '../../../../static/images/icon-arrow.inline.svg';
import BookmarkIcon from '../../../../static/images/icon-bookmark.inline.svg';
import CodeLaptopLogo from '../../../../static/images/icon-code-laptop.inline.svg';
import LightbulbIcon from '../../../../static/images/icon-lightbulb.inline.svg';
import SymbolsLarge from '../../../../static/images/td-symbols-large.inline.svg';
import SymbolsSmall from '../../../../static/images/td-symbols-small.inline.svg';

const FeatureItem = ({ title, text, imageUrl }: { title: string; text: string; imageUrl: string }) => {
  return (
    <article className="bg-tower-defense-gray-light p-24 rounded-[32px]">
      <div className="flex flex-col md:flex-row lg:flex-col gap-16">
        <img src={imageUrl} alt="" className="rounded-[16px] object-cover md:w-[300px] lg:w-full" />
        <div>
          <h3 className="text-white font-bold text-20">{title}</h3>
          <p className="mt-8 text-white">{text}</p>
        </div>
      </div>
    </article>
  );
};

const Features = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-tower-defense-gray-default pt-72 pb-72 md:pt-96 overflow-x-clip">
      <div className="container-slim relative">
        <div>
          <div className="absolute -left-[140px] -top-[80px] hidden md:block">
            <SymbolsLarge />
          </div>
          <div className="absolute -right-[140px] -bottom-[240px] hidden md:block z-20">
            <SymbolsSmall />
          </div>
          <div className="relative z-30">
            <h2 className="text-32 xs:text-48 text-white font-bold">
              <Trans>Built With Paima Engine - Unlocking The Next Generation Of Web3 Games</Trans>
            </h2>
            <p className="mt-16 text-white">
              <Trans>
                Paima Engine is the only blockchain gaming engine that enables developers to reuse their Web2
                programming skill sets to build fully trustless Web3 games (or apps). Devs can apply their years of
                knowledge in JavaScript, relational databases, and all parts of the classic Web2 stack with Paima
                Engine, while fully taking advantage of the bleeding edge in Layer-2 blockchain technology.
              </Trans>
            </p>
            <div className="mt-40 grid grid-cols-1 lg:grid-cols-3 gap-24">
              <FeatureItem
                title={t('Unity Frontend')}
                text={t(
                  'Wrath of the Jungle: Tower Defense uses Unity for its frontend, taking advantage of industry-standard game development tooling. Paima Engine allows developers to use the stacks that they are familiar with to get the most out of their time, and focus strictly on their job: making great games.'
                )}
                imageUrl="/images/td-game-frontend.webp"
              />{' '}
              <FeatureItem
                title={t('Full On-Chain Integration')}
                text={t(
                  'Games built with Paima Engine, like Wrath of The Jungle: Tower Defense, are full-fledged Web3 games that live on-chain. Every game input a user submits is posted and lives forever in history, allowing for deep blockchain integration and new features like Stateful NFTs!'
                )}
                imageUrl="/images/td-blockchain.webp"
              />
              <FeatureItem
                title={t('Paima Game Node')}
                text={t(
                  "The backend for games built with Paima Engine are in fact 'Game Nodes' just like a 'Blockchain Node' which you may be more familiar with. In other words, by simply running the game node you can always pick up and play the game again, whether today, or in a decade!"
                )}
                imageUrl="/images/td-game-node.webp"
              />
            </div>
            <div className="mt-48">
              <h4 className="text-white font-bold text-24">
                <Trans>Start building your own Web3 games today</Trans>
              </h4>
              <div className="mt-16 flex flex-col md:flex-row md:items-center gap-16 flex-wrap">
                <TowerDefenseButton
                  iconComponent={<CodeLaptopLogo />}
                  to="https://paimastudios.com/contact/"
                  renderAsLink
                  target="_blank"
                >
                  <Trans>Build your own game</Trans>
                </TowerDefenseButton>
                <TowerDefenseButton
                  iconComponent={<BookmarkIcon />}
                  outlineVariant
                  to="https://www.youtube.com/watch?v=HtvemijxF-0"
                  renderAsLink
                  target="_blank"
                >
                  <Trans>Learn the basics</Trans>
                </TowerDefenseButton>
                <TowerDefenseButton
                  iconComponent={<LightbulbIcon />}
                  outlineVariant
                  to="https://www.youtube.com/watch?v=2-FTP-qbml8"
                  renderAsLink
                  target="_blank"
                >
                  <Trans>Paima Engine in Practice</Trans>
                </TowerDefenseButton>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-48">
          <Link
            to="#"
            className="text-center w-full text-white font-18 uppercase flex flex-col items-center gap-16"
            onClick={(e) => {
              e.preventDefault();
              scrollTo('#about');
            }}
          >
            <Trans>Learn more about the game</Trans>
            <div className="fill-white">
              <ArrowIcon />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Features;
