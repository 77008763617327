import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';

import { getLeaderboardData } from '../../../services/utils';
import { LeaderboardEntryProps } from '../leaderboard/LeaderboardEntry';
import LeaderboardCard from './LeaderboardCard';
import TowerDefenseButton from './TowerDefenseButton';

import JoystickIcon from '../../../../static/images/icon-joystick.inline.svg';
import MedalIcon from '../../../../static/images/icon-medal.inline.svg';
import SymbolsLarge from '../../../../static/images/td-symbols-large.inline.svg';
import SymbolsSmall from '../../../../static/images/td-symbols-small.inline.svg';
import { getLocalizedLink } from '../../../utils/locale';

const LeaderboardSection = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<LeaderboardEntryProps[] | null>(null);

  useEffect(() => {
    (async () => {
      setData(await getLeaderboardData('tower-defense'));
    })();
  }, []);

  return (
    <div className="bg-tower-defense-gray-default pt-72 pb-72 md:pt-96 overflow-x-clip">
      <div className="container-slim relative">
        <div className="absolute -left-[140px] -top-[80px] hidden md:block">
          <SymbolsLarge />
        </div>
        <div className="absolute -right-[140px] -bottom-[240px] hidden md:block z-20">
          <SymbolsSmall />
        </div>
        <div className="relative z-30">
          <h2 className="text-32 xs:text-48 text-white font-bold">
            <Trans>Leaderboards</Trans>
          </h2>
          <p className="mt-16 text-white">
            <Trans>
              Enjoy the thrill of proving that you are the best in competitive PvP games? Get to the top of the Wrath of
              the Jungle: Tower Defense Leaderboards with your Volcaneer Stateful NFTs! Players who do well will get
              rewards in upcoming Paima Studios games!
            </Trans>
          </p>
          {!!data && (
            <div className="mt-48 p-16 md:p-24 lg:p-32 td-glass-bg rounded-[32px]">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-48 md:gap-24 pt-24 items-end">
                <LeaderboardCard
                  {...data[1]}
                  position={data.findIndex((searchEntry) => searchEntry.token_id === data[1].token_id) + 1}
                  name={`${t('Paima Volcaneer')} #${data[1].token_id}`}
                  wrapperClassname="order-2 md:order-1"
                />
                <LeaderboardCard
                  {...data[0]}
                  position={data.findIndex((searchEntry) => searchEntry.token_id === data[0].token_id) + 1}
                  name={`${t('Paima Volcaneer')} #${data[0].token_id}`}
                  largeVariant
                  wrapperClassname="order-1 md:order-2"
                />
                <LeaderboardCard
                  {...data[2]}
                  position={data.findIndex((searchEntry) => searchEntry.token_id === data[2].token_id) + 1}
                  name={`${t('Paima Volcaneer')} #${data[2].token_id}`}
                  wrapperClassname="order-3 md:order-3"
                />
              </div>
              <div className="mt-24 lg:mt-32 flex flex-col md:flex-row md:items-center md:justify-center gap-16 flex-wrap">
                <TowerDefenseButton
                  iconComponent={<MedalIcon />}
                  customClassName="min-w-[260px]"
                  renderAsLink
                  to={getLocalizedLink('/tower-defense/leaderboard', i18n.language)}
                >
                  <Trans>See Leaderboards</Trans>
                </TowerDefenseButton>
                <TowerDefenseButton
                  iconComponent={<JoystickIcon />}
                  outlineVariant
                  customClassName="min-w-[260px]"
                  renderAsLink
                  to="https://tower-defense.paimastudios.com"
                  target="_blank"
                >
                  <Trans>Play now</Trans>
                </TowerDefenseButton>
              </div>
            </div>
          )}
          <div className="flex flex-col md:flex-row gap-24 mt-48">
            <div>
              <h3 className="text-32 font-bold text-white">
                <Trans>Paima Primitive: Stateful NFTs!</Trans>
              </h3>
              <p className="mt-16 text-white">
                <Trans>
                  NFTs in gaming have had a bad reputation because they lacked power to create compelling games. Players
                  desire NFTs that evolve with them, gaining experience, equipment, and developing over time. Paima
                  Engine introduces Stateful NFTs, unlocking a new era of Web3 games.
                </Trans>
              </p>
              <p className="mt-16 text-white">
                <Trans>
                  For Wrath of the Jungle: Tower Defense, the Volcaneers are the first Stateful NFT available which
                  track your in-game record. Prove you're the best by rising to the top of the Stateful NFT leaderboard,
                  and you'll lock yourself in for receiving rewards in upcoming Paima Studios games!
                </Trans>
              </p>
              <div className="mt-32 flex flex-col xl:flex-row xl:items-center xl:justify-start gap-16">
                <TowerDefenseButton renderAsLink to="https://paimastudios.com/volcaneers" target="_blank">
                  <Trans>Buy A Volcaneer</Trans>
                </TowerDefenseButton>
                <TowerDefenseButton
                  renderAsLink
                  to="https://www.youtube.com/watch?v=Fhq5XX-JgzY&t=1501s"
                  target="_blank"
                  outlineVariant
                >
                  <Trans>Learn more</Trans>
                </TowerDefenseButton>
              </div>
            </div>
            <img src="/images/td-paima-nft.webp" alt="" className="max-w-full md:max-w-[530px] mx-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardSection;
