import classNames from 'classnames';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';

const CloseIcon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path d="M6 16C6 15.1716 6.67157 14.5 7.5 14.5H24.5C25.3284 14.5 26 15.1716 26 16C26 16.8284 25.3284 17.5 24.5 17.5H7.5C6.67157 17.5 6 16.8284 6 16Z" />
    </g>
  </svg>
);

const OpenIcon = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.6">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 7.5C17.5 6.67157 16.8284 6 16 6C15.1716 6 14.5 6.67157 14.5 7.5V14.5H7.5C6.67157 14.5 6 15.1716 6 16C6 16.8284 6.67157 17.5 7.5 17.5H14.5V24.5C14.5 25.3284 15.1716 26 16 26C16.8284 26 17.5 25.3284 17.5 24.5V17.5H24.5C25.3284 17.5 26 16.8284 26 16C26 15.1716 25.3284 14.5 24.5 14.5H17.5V7.5Z"
      />
    </g>
  </svg>
);

interface FAQQuestionProps {
  question: string;
  answer: string | React.ReactElement;
}

const FAQQuestion = ({ question, answer }: FAQQuestionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li className="td-glass-bg p-16 pl-24 rounded-[32px] text-white">
      <div
        className="flex items-center justify-between w-full"
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Toggle question"
        role="button"
      >
        <h3 className="font-bold text-20 leading-8 md:flex-1 text-left">{question}</h3>
        <div className="ml-8 fill-white">{isOpen ? CloseIcon : OpenIcon}</div>
      </div>
      {isOpen && (
        <div className="pt-16">
          <hr className="text-[#4D5763]" />
          <p className="pt-16  text-16 leading-7">{answer}</p>
        </div>
      )}
    </li>
  );
};

interface FAQProps {
  includeCharacters?: boolean;
  questions: FAQQuestionProps[];
}

const FAQ = ({ includeCharacters = false, questions }: FAQProps) => {
  return (
    <div className="bg-[url('/images/td-faq-background.webp')] bg-center bg-cover bg-no-repeat relative">
      <div className="absolute left-0 right-0 top-0 bottom-0 w-full h-full td-bg-gradient" />
      <div
        id="faq"
        className={classNames(
          'pt-40',
          'lg:pt-[10vw]',
          'pb-64',
          'lg:pb-[140px]',
          'relative',
          includeCharacters && 'overflow-hidden',
          'scroll-mt-[140px]',
          'md:scroll-mt-[130px]',
          'lg:scroll-mt-[110px]'
        )}
      >
        <div className="container-slim relative z-20">
          <h2 className="text-32 xs:text-40 text-white font-bold">
            <Trans>FAQ</Trans>
          </h2>
          <p className="text-white mt-16 max-w-[800px]">
            <Trans>
              Find answers to common questions that other players have asked previously about the game below.
            </Trans>
          </p>
          <ul className="space-y-12 relative z-20 mt-48">
            {questions.map(({ question, answer }, index) => (
              <FAQQuestion question={question} answer={answer} key={index} />
            ))}
            {includeCharacters && (
              <>
                <div className="absolute -left-[145px] top-[200px] z-[-1] w-[200px] h-[337px] -rotate-[10deg] hidden xl:block">
                  <img src="/images/td-faq-character-2.webp" alt="" className="w-full" />
                </div>
                <div className="absolute -right-[110px] -top-[150px] z-[-1] w-[200px] h-[400px] rotate-[30deg] hidden xl:block">
                  <img src="/images/td-faq-character-1.webp" alt="" className="w-full" />
                </div>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
