import React from 'react';

const FactionContentWrapper = ({
  children
}: {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode | React.ReactNode[];
}) => {
  return <div className="td-glass-bg rounded-[32px] py-24 px-16 md:p-24 lg:p-32 text-white">{children}</div>;
};

export default FactionContentWrapper;
