import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import classNames from 'classnames';
import FactionContentInner from './FactionContentInner';
import FactionContentPanel from './FactionContentPanel';
import FactionContentWrapper from './FactionContentWrapper';

const FightForGloryDefender = () => {
  const { t } = useTranslation();

  return (
    <FactionContentWrapper>
      <FactionContentInner>
        <div>
          <video
            className={classNames(
              'w-full',
              'h-full',
              'object-cover',
              'aspect-square',
              'rounded-[24px]',
              'max-w-[450px] max-h-[450px]'
            )}
            playsInline
            autoPlay
            muted
            loop
          >
            <source className="object-cover" src="/video/td-defend.mp4" type="video/mp4" />
          </video>
        </div>
        <FactionContentPanel
          title={t('Defend Your Base And Fight For Jungle Glory')}
          description={[
            t(
              "In Wrath of the Jungle: Tower Defense, a defender must strategically place towers and other defensive structures to prevent waves of enemies from reaching their base. The ultimate goal is to protect the jungle and emerge victorious against the attacker's increasingly expanding waves. As a defender, players must use their resources wisely and adapt their building plans to counter the strengths and weaknesses of different enemy crypts."
            )
          ]}
        />
      </FactionContentInner>
    </FactionContentWrapper>
  );
};

export default FightForGloryDefender;
