import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import FactionContentInner from './FactionContentInner';
import FactionContentPanel from './FactionContentPanel';
import FactionContentWrapper from './FactionContentWrapper';

const GiveOrders = () => {
  const { t } = useTranslation();

  return (
    <FactionContentWrapper>
      <FactionContentInner>
        <div>
          <img src="/images/td-give-orders.webp" alt="" />
        </div>
        <FactionContentPanel
          title={t('Orders')}
          description={[
            t(
              'In Wrath of the Jungle: Tower Defense, the undead animals of the jungle are at your disposal to wreak havoc. The great injustice of when they originally died has lead them to live on as unruly spirits waiting for the chance to come back and seek revenge. Lucky for them, the world of on-chain gaming has given them the chance to materialize once more and show those who yet live the terror they once experienced themselves.'
            ),
            t('')
          ]}
        />
      </FactionContentInner>
    </FactionContentWrapper>
  );
};

export default GiveOrders;
