import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';

import classNames from 'classnames';
import FactionContentInner from './FactionContentInner';
import FactionContentPanel from './FactionContentPanel';
import FactionContentWrapper from './FactionContentWrapper';

const cryptsData = [
  {
    id: 0,
    name: 'Gorilla Crypt',
    descriptions: [
      "The Gorilla Crypt is the tanky high-health unit that strikes fear in any defender. They may be slow to arrive at your opponent's base, but they sure can survive through a lot of hits by most towers! No defender is ever at peace when they see an entire column of Tier 3 Gorilla's marching away towards their base! Gorilla's do best against Pirranha towers, but with great strategic wit can cause problems no matter what the defender has planned."
    ],
    imageUrl: '/images/td-crypt-gorilla.webp'
  },
  {
    id: 1,
    name: 'Jaguar Crypt',
    descriptions: [
      "The Jaguar Crypt spawns speedy units that dash through the lanes towards the defender's base at top speeds. Though easily beaten with their low hp, these units have a secret card up their sleeve: they are completely invulnerable to Sloth Tower attacks! Jaguars are an extremely strategic unit that can quickly turn the tide of the game if the defender wasn't planning ahead far enough."
    ],
    imageUrl: '/images/td-crypt-jaguar.webp'
  },
  {
    id: 2,
    name: 'Macaw Crypt',
    descriptions: [
      "The Macaws are a one-of-a-kind unit that always leaves the defender with a lump in their throat! Macaws fit in the middle between Jaguar's speed and Gorilla's high health, but that isn't what makes them special. Macaws attack and destroy enemy towers as they pass by! This makes them a foe always worth keeping tabs on, especially when placing low hp Anaconda towers which are extremely vulnerable!"
    ],
    imageUrl: '/images/td-crypt-macaw.webp'
  }
];

const SetupCrypts = () => {
  const { t } = useTranslation();

  const [selectedCrypt, setSelectedCrypt] = useState(cryptsData[0]);

  return (
    <FactionContentWrapper>
      <FactionContentInner>
        <div className="flex flex-col-reverse md:flex-row gap-24 md:gap-40">
          <div className="flex md:flex-col gap-8 sm:gap-16 justify-center">
            {cryptsData.map((crypt, index) => (
              <div
                role="button"
                aria-label={crypt.name}
                key={index}
                className="max-w-[80px] lg:max-w-[96px] text-center space-y-8"
                onClick={() => setSelectedCrypt(cryptsData.find((crypt) => crypt.id === index) ?? cryptsData[0])}
              >
                <div
                  className={classNames(
                    'w-[80px] h-[80px] lg:w-[96px] lg:h-[96px]',
                    'td-glass-bg',
                    'rounded-[16px]',
                    'flex items-center justify-center',
                    'p-8',
                    'border',
                    index === selectedCrypt.id ? 'border-white/60' : 'border-transparent'
                  )}
                >
                  <img
                    src={crypt.imageUrl}
                    alt={crypt.name}
                    className={classNames(
                      'max-w-full max-h-full',
                      index === selectedCrypt.id ? 'opacity-100' : 'opacity-60'
                    )}
                  />
                </div>
                <p className={classNames(index === selectedCrypt.id ? 'text-white' : 'text-white/60', 'leading-5')}>
                  <Trans>{crypt.name}</Trans>
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-col justify-center lg:items-start items-center flex-1">
            <img src={selectedCrypt.imageUrl} alt="" className="md:max-w-[320px]" />
          </div>
        </div>
        <FactionContentPanel title={t(selectedCrypt.name)} description={t(selectedCrypt.descriptions)} />
      </FactionContentInner>
    </FactionContentWrapper>
  );
};

export default SetupCrypts;
