import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';

import classNames from 'classnames';
import FactionContentInner from './FactionContentInner';
import FactionContentPanel from './FactionContentPanel';
import FactionContentWrapper from './FactionContentWrapper';

const towersData = [
  {
    id: 0,
    name: 'Anaconda Tower',
    descriptions: [
      "The Anaconda Tower is a powerful defensive structure which is a bit slow, but hits hard! Every undead unit spawned from the depths, no matter how strong they may be, always fears the bite of the Anaconda. Anaconda's can manage to take out Gorillas in a single bite, making any enemy unit quiver as it runs by. Though ferocious, anaconda towers can be seen as glass towers. They pack a punch, but a well placed Macaw Crypt can ruin its day by destroying it in mere seconds!"
    ],
    imageUrl: '/images/td-tower-anaconda.webp'
  },
  {
    id: 1,
    name: 'Piranha Tower',
    descriptions: [
      "The Piranha Tower acts as the long-ranged high-firing rate turret you always knew you wanted. Place a Piranha tower down and no unit shall pass without getting a quick tap on the head with a stream of water! Though Piranha's don't deal high damage, their quick speed makes them the perfect counter against Jaguars, and they often do great finishing off that last tiny bit of HP on gorillas who would have made it through otherwise!"
    ],
    imageUrl: '/images/td-tower-piranha.webp'
  },
  {
    id: 2,
    name: 'Sloth Tower',
    descriptions: [
      "The Sloth Tower may seem like it's not doing all that much at first glance, but it's lack of fast moving attacks is just a clever disguise for the strength it holds within. Unlike any of the other structures, the Sloth Tower has AOE damage which hits multiple units in range at the same time! With it's additional high health, Sloths seem like they were born to rule the jungle. That is however, until one finds out that pesky Jaguars rain on the Sloth parade, as their quick speed makes them completely invulnerable to Sloth Tower attacks!"
    ],
    imageUrl: '/images/td-tower-sloth.webp'
  }
];

const SetupTowers = () => {
  const { t } = useTranslation();

  const [selectedTower, setSelectedTower] = useState(towersData[0]);

  return (
    <FactionContentWrapper>
      <FactionContentInner>
        <div className="flex flex-col-reverse md:flex-row gap-24 md:gap-40">
          <div className="flex md:flex-col gap-8 sm:gap-16 justify-center">
            {towersData.map((tower, index) => (
              <div
                role="button"
                aria-label={tower.name}
                key={index}
                className="max-w-[80px] lg:max-w-[96px] text-center space-y-8"
                onClick={() => setSelectedTower(towersData.find((tower) => tower.id === index) ?? towersData[0])}
              >
                <div
                  className={classNames(
                    'w-[80px] h-[80px] lg:w-[96px] lg:h-[96px]',
                    'td-glass-bg',
                    'rounded-[16px]',
                    'flex items-center justify-center',
                    'p-8',
                    'border',
                    index === selectedTower.id ? 'border-white/60' : 'border-transparent'
                  )}
                >
                  <img
                    src={tower.imageUrl}
                    alt={tower.name}
                    className={classNames(
                      'max-w-full max-h-full',
                      index === selectedTower.id ? 'opacity-100' : 'opacity-60'
                    )}
                  />
                </div>
                <p className={classNames(index === selectedTower.id ? 'text-white' : 'text-white/60', 'leading-5')}>
                  <Trans>{tower.name}</Trans>
                </p>
              </div>
            ))}
          </div>
          <div className="flex flex-col justify-center lg:items-start items-center flex-1">
            <img src={selectedTower.imageUrl} alt="" className="md:max-w-[320px]" />
          </div>
        </div>
        <FactionContentPanel title={t(selectedTower.name)} description={t(selectedTower.descriptions)} />
      </FactionContentInner>
    </FactionContentWrapper>
  );
};

export default SetupTowers;
