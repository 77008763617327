import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement } from 'react';

import FactionContentInner from './FactionContentInner';
import FactionContentPanel from './FactionContentPanel';
import FactionContentWrapper from './FactionContentWrapper';

import AstarIcon from '../../../../../static/images/icon-astar.inline.svg';
import CardanoIcon from '../../../../../static/images/icon-cardano.inline.svg';
import EthereumIcon from '../../../../../static/images/icon-ethereum.inline.svg';
import FlintIcon from '../../../../../static/images/icon-flint.inline.svg';
import MetamaskIcon from '../../../../../static/images/icon-metamask.inline.svg';

const SubmitTile = ({ logoComponent }: { logoComponent: ReactElement }) => {
  return (
    <div className="w-[80px] h-[80px] md:w-[100px] md:h-[100px] lg:w-[140px] lg:h-[140px] rounded-[16px] td-glass-bg flex items-center justify-center p-16 md:p-24">
      <div className="w-[40px] h-[40px] lg:w-[64px] lg:h-[64px] flex items-center justify-center">{logoComponent}</div>
    </div>
  );
};

const SubmitTransactionAttacker = () => {
  const { t } = useTranslation();

  return (
    <FactionContentWrapper>
      <FactionContentInner>
        <div className="space-y-48">
          <div className="space-y-24">
            <div className="flex items-center gap-16">
              <p className="text-white text-24 font-bold w-[48px] h-[48px] flex items-center justify-center rounded-full border border-white">
                1
              </p>
              <h4 className="text-white text-24 font-bold">
                <Trans>Pick the network</Trans>
              </h4>
            </div>
            <div className="flex gap-8 lg:gap-16">
              <SubmitTile logoComponent={<EthereumIcon />} />
              <SubmitTile logoComponent={<CardanoIcon />} />
              <SubmitTile logoComponent={<AstarIcon />} />
            </div>
          </div>
          <div className="space-y-24">
            <div className="flex items-center gap-16">
              <p className="text-white text-24 font-bold w-[48px] h-[48px] flex items-center justify-center rounded-full border border-white">
                2
              </p>
              <h4 className="text-white text-24 font-bold">
                <Trans>Pick a wallet</Trans>
              </h4>
            </div>
            <div className="flex gap-8 lg:gap-16">
              <SubmitTile logoComponent={<MetamaskIcon />} />
              <SubmitTile logoComponent={<FlintIcon />} />
              <SubmitTile logoComponent={<AstarIcon />} />
            </div>
          </div>
        </div>
        <FactionContentPanel
          title={t('Use Your Favorite Crypto Wallet')}
          description={[
            t(
              "Thanks to Paima Whirlpool, Wrath of the Jungle: Tower Defense has full-fledged Cross-chain Play support! This means no matter if you are on Cardano using Flint wallet, on Astar using a Polkadot wallet, or on Ethereum using Metamask, you can get started playing instantly! Better yet, when you start playing we subsidize your transactions so you don't even need to spend a single penny! Just boot up the game with your favorite wallet, and instantly get a taste of the future of Web3 gaming like never before."
            )
          ]}
        />
      </FactionContentInner>
    </FactionContentWrapper>
  );
};

export default SubmitTransactionAttacker;
