import React from 'react';

const FactionContentInner = ({
  children
}: {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode | React.ReactNode[];
}) => {
  return <div className="grid grid-cols-1 gap-24 lg:grid-cols-2 lg:gap-32">{children}</div>;
};

export default FactionContentInner;
