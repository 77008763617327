import classNames from 'classnames';
import React from 'react';

interface CornerNFTBadgeProps {
  size?: 'small' | 'large';
}

const CornerNFTBadge = ({ size }: CornerNFTBadgeProps) => {
  return (
    <div className={classNames(size === 'small' ? 'w-[40px] h-[40px]' : 'w-[80px] h-[80px]')}>
      <div
        className={classNames(
          'flex justify-center',
          'td-badge-gradient td-badge-clip-path w-full h-full',
          size === 'small' ? 'rounded-tr-[12px]' : 'rounded-tr-[24px]'
        )}
      >
        <p
          className={classNames(
            'rotate-45 text-center font-black font-base text-[#353D40] uppercase',
            size === 'small' ? 'pt-[8px] text-[6px] leading-[8px]' : 'pt-[10px] text-[9px] leading-[12px]'
          )}
        >
          <span className={classNames('block', size === 'small' ? 'text-[7px]' : 'text-[10px]')}>Reward</span>{' '}
          <span className="hidden md:inline-block">Tarochi gold</span>
        </p>
      </div>
    </div>
  );
};

export default CornerNFTBadge;
